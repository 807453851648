// src/components/ClientArea.jsx
import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import safeStringify from 'safe-stringify';
import ClientDashboard from './ClientDashboard';
import EmailVerificationInstructions from './EmailVerificationInstructions';
import ClientLoading from './ClientLoading';

export default function ClientArea() {
    const { user, loading } = useAuth();
    const [rootComponent, setRootComponent] = useState(<ClientLoading />);
    const [debugMode, setDebugMode] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('debug')) {
            setDebugMode(true);
        }
    }, []);

    useEffect(() => {
        if (loading) {
            setRootComponent(<ClientLoading />);
        } else if (user) {
            if(user.emailVerified) {
                setRootComponent(<ClientDashboard />);
            } else {
                setRootComponent(<EmailVerificationInstructions />);
            }
        }
    }, [user, loading]);

    return (
        <section>
            {rootComponent}
            {debugMode && (
                <p id='debug' className='text-xs text-gray-500'>{safeStringify(user)}</p>
            )}
        </section>
    );
}
