// src/pages/DocumentPage.jsx
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import DocumentLayout from "../layouts/DocumentLayout";
import FileOpen from '../components/FileOpen';
import PdfThumbnails from '../components/PdfThumbnails';
import { openDB } from 'idb';
import { IDB_NAME, IKEY_NAME, ISTORE_NAME } from '../data/constants';

export default function DocumentPage() {
    const location = useLocation();

    const [file, setFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    pdfjs.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.min.mjs';

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        window.scrollTo(0, 0);
    };

    const onDocumentLoadError = (error) => {
        console.error("Error while loading document:", error);
        setFile(null);
    };


    async function getFromIndexedDB() {
        const db = await openDB(IDB_NAME, 1);
        return await db.get(ISTORE_NAME, IKEY_NAME);
    }


    useEffect(() => {
        async function loadFile() {
            const storedData = await getFromIndexedDB();
            console.log("iLoad the file from IndexedDB", storedData);
            if (storedData) {
                setFile(storedData.file);
            }
        }
        loadFile();
    }, [location]);

    return (
        <DocumentLayout>
            {file ? (
                <div className="flex">
                    {/* Thumbnails on the left */}
                    <div className="w-1/6 overflow-y-auto h-screen p-4 bg-gray-100">
                        <PdfThumbnails numPages={numPages} file={file} />
                    </div>

                    {/* Main PDF Viewer */}
                    <div className="w-3/4 overflow-y-scroll h-screen p-4">
                        <Document
                            file={file}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onDocumentLoadError}
                            loading={<div>Loading...</div>}
                            onError={onDocumentLoadError}
                        >
                            {numPages &&
                                Array.from(new Array(numPages), (el, index) => (
                                    <Page size="A4"
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        className="mb-4"
                                        id={`page_${index + 1}`}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                    />
                                ))}
                        </Document>
                    </div>
                </div>
            ) : (
                <FileOpen />
            )}
        </DocumentLayout>
    );
}
