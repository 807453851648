// src/components/Footer.jsx
import React from "react";
import Logo from "./Logo";
import version from "../version";

// Footer Links Array
const linksArray = [
  { href: "/privacy", label: "Privacy Policy" },
  { href: "/terms", label: "Terms of Service" },
  { href: "/roadmap", label: "Roadmap" }
];

export default function Footer() {
  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          {/*  Project Description */}
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-6">
            <h4 className="text-lg font-bold">SmrtSgn.com</h4>
            <p className="mt-2">
              SmrtSgn is a modern digital signature platform that simplifies the document signing process for individuals and businesses, providing secure and convenient solutions.
            </p>
          </div>

          <div className="w-full sm:w-1/2 lg:w-1/4 mb-6"></div>

          {/*  Legal Information */}
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-6">
            <h4 className="text-lg font-bold">Legal Information</h4>
            <ul className="mt-2 space-y-2">
              {linksArray.map((link, index) => (
                <li key={index}>
                  <a href={link.href} className="hover:underline">
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/*  Contact Information */}
          <div className="w-full sm:w-1/2 lg:w-1/4 mb-6">
            <h4 className="text-lg font-bold">Contacts</h4>
            <p className="mt-2">
              Email: <a href="mailto:iryna@smrtsgn.com" className="hover:underline">iryna@smrtsgn.com</a>
            </p>
            <p className="mt-2">
              Team: <a href="mailto:team@snapsign.com.au" className="hover:underline">team@snapsign.com.au</a>
            </p>
            <p className="mt-2">
              <a href="/contacts" className="hover:underline">Contact Form</a>
            </p>
          </div>

          
          
        </div>

        {/* Footer Logo */}
        <div className="text-center flex items-center my-4">
          <hr className="w-1/3"/><Logo className="mx-auto" alt={true} /><hr className="w-1/3"/>
        </div>

        {/* Copyright */}
        <div className="text-center flex items-center justify-between text-sm text-gray-400">
          <span>© {currentYear} SmrtSgn by <a href="https://snapsign.com.au" className="hover:underline">SnapSign</a>. All Rights Reserved.</span>
          <span className="mx-2">Wep App version: <a href="https://vasilkoff.com" target="_blank" rel="noreferrer" className="hover:underline">{version}</a></span>
        </div>
      </div>
    </footer>
  );
}
