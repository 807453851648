// src/pages/TermsPage.jsx
import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";

export default function TermsPage() {
    return (
        <DefaultLayout>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
                <p className="mb-4">
                    Welcome to our Terms and Conditions page. These terms outline the rules and regulations for the use of our website and services.
                </p>
                
                <h2 className="text-2xl font-semibold mb-3">Acceptance of Terms</h2>
                <p className="mb-4">
                    By accessing and using our website and services, you agree to comply with and be bound by these terms. If you disagree with any part of the terms, please do not use our services.
                </p>
                
                <h2 className="text-2xl font-semibold mb-3">Services Provided</h2>
                <p className="mb-4">
                    We provide services related to electronic document signing and AI-powered document analysis. These services are provided "as is," and we make no guarantees as to their accuracy or reliability.
                </p>
                
                <h2 className="text-2xl font-semibold mb-3">User Obligations</h2>
                <p className="mb-4">
                    As a user, you agree not to misuse our services for illegal or unethical purposes. You must ensure that all documents you upload comply with local and international laws.
                </p>
                
                <h2 className="text-2xl font-semibold mb-3">Limitation of Liability</h2>
                <p className="mb-4">
                    We are not liable for any losses or damages resulting from the use of our services. You agree to use our services at your own risk.
                </p>
                
                <h2 className="text-2xl font-semibold mb-3">Modifications to Terms</h2>
                <p className="mb-4">
                    We reserve the right to modify these terms at any time. It is your responsibility to review the terms regularly to ensure you are aware of any changes.
                </p>
                
                <h2 className="text-2xl font-semibold mb-3">Contact Information</h2>
                <p>
                    If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:team@snapsign.com.au">team@snapsign.com.au</a>.
                </p>
            </div>
        </DefaultLayout>
    );
}
