// src/components/Navbar.jsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { FaBars } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { useAuth } from "../context/AuthContext";

export default function Navbar() {
  const { user } = useAuth();
  const [activeLinks, setActiveLinks] = useState([]);
  const [navbarOpen, setNavbarOpen] = useState(false);

  useEffect(() => {
    const navLinks = [{ to: "/client", label: "Sign In", type: "link" }];
    const authNavLinks = [
      { to: "/client", label: "Dashboard", type: "link" },
      { label: "Sign Out", type: "button", onClick: () => auth.signOut() },
    ];
    if (user) {
      setActiveLinks(authNavLinks);
    } else {
      setActiveLinks(navLinks);
    }
  }, [user]);

  return (
    <nav className="flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-end lg:w-auto lg:static lg:block lg:justify-start">
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {!navbarOpen ? <FaBars /> : <CgClose />}
          </button>
        </div>
        <div
          className={`lg:flex flex-grow items-center transition-all duration-500 ease-in-out ${
            navbarOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
          } lg:max-h-full lg:opacity-100 overflow-hidden`}
          id="navbar-menu"
        >
          {/* Render navigation links based on authentication status */}
          <ul className="flex flex-col lg:flex-row list-none mr-auto">
            {activeLinks.map((link, index) => (
              <li key={index} className="flex items-center">
                {link.type === "link" && (
                  <Link
                    to={link.to}
                    className="hover:text-violet-800 px-3 py-4 lg:py-2 flex items-center text-base"
                  >
                    {link.label}
                  </Link>
                )}
                {link.type === "button" && (
                  <button
                    onClick={link.onClick}
                    className="btn shadow px-3 py-4 lg:py-2 flex items-center text-base"
                  >
                    {link.label}
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}
