// src/components/HeroActionSection.jsx

import FileOpen from "./FileOpen";


export default function HeroActionSection() {

    return (
        <section className="py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between items-start">
                    <div className="w-full sm:w-1/2 sm:p-6 py-6">
                        <h1 className="text-2xl font-bold">Feel Safe When You Snap Sign with SmrtSgn</h1>
                        <p className="mt-2">
                        AI that reads and understands legal documents, so you don’t have to worry. SmrtSgn ensures every contract is clear, compliant, and secure.
                        </p>  
                        <p className="mt-2">Upload your document, and let AI handle the complexity. 
                            Our app ensures that You fully understand what you’re signing.</p>  
                    </div>
                    <div className="w-full sm:w-1/2 sm:p-6 py-6">
                        <FileOpen />
                    </div>      
                </div>
            </div>
        </section>
    );
}