// src/components/ExplanatorySection.jsx

export default function ExplanatorySection() {
    return (
        <section className="py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between items-start">
                    {/* Left Column */}
                    <div className="w-full sm:w-1/2 sm:p-6 py-6">
                        <h2 className="text-2xl font-bold">What is SmrtSgn?</h2>
                        <p className="mt-4 text-gray-700">
                            SmrtSgn is more than just an e-signature tool. It's an AI-powered solution that simplifies legal documents, ensuring you fully understand what you’re signing before you make any commitment.
                        </p>
                        <p className="mt-4 text-gray-700">
                            Whether it’s interpreting complex terms, translating legal jargon, or spotting hidden risks, SmrtSgn makes sure you sign with confidence. No more confusing legal language or tiny fine print – we’ve got you covered.
                        </p>
                        <p className="mt-4 text-gray-500 text-sm">
                            Rest assured, your document never leaves your browser. Only the text is sent for analysis, keeping your files private and secure.
                        </p>
                    </div>

                    {/* Right Column */}
                    <div className="w-full sm:w-1/2 sm:p-6 py-6">
                        <h2 className="text-2xl font-bold">How SmrtSgn Helps</h2>
                        <p className="mt-4 text-gray-700">
                            - AI reads and flags potential issues in your documents.
                        </p>
                        <p className="mt-2 text-gray-700">
                            - Explains difficult legal terms in plain language or translates them into your preferred language.
                        </p>
                        <p className="mt-2 text-gray-700">
                            - Ensures your agreement complies with privacy and consumer protection laws.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

