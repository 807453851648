// src/pages/HomePage.jsx

import EncouragementSection from "../components/EncouragementSection";
import ExplanatorySection from "../components/ExplanatorySection";
import HeroActionSection from "../components/HeroActionSection";
import DefaultLayout from "../layouts/DefaultLayout";


export default function HomePage() {
    return (
        <DefaultLayout>
            <HeroActionSection />
            <ExplanatorySection />
            <EncouragementSection />
        </DefaultLayout>
    );
}
