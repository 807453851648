// src/data/timelines.js

const TimeLineItems = [
    {
        title: "Q1 2024",
        cardTitle: "Idea Formation",
        cardSubtitle: "Initial Idea for SmrtSgn",
        cardDetailedText: "The idea for SmrtSgn was conceived in Australia, Northern Territory. Initial brainstorming on how to build a smart, AI-powered electronic signature tool that simplifies legal documents."
    },
    {
        title: "Q2 2024",
        cardTitle: "Pitch and Preparation",
        cardSubtitle: "Pitch Deck and Strategic Planning",
        cardDetailedText: "The idea was shaped into a formal brief and pitch deck. Snap Sign Pty Ltd was prepared to move forward with business strategy and planning."
    },
    {
        title: "Q3 2024",
        cardTitle: "Company Registration & Funding",
        cardSubtitle: "Snap Sign Pty Ltd Registered",
        cardDetailedText: "Snap Sign Pty Ltd was officially registered with Iryna Vasylkova as the Founder and CEO. Snap Sign entered the Startup September program, applied for the NT Government Level 1 grant for MVP development with InnovationHub Darwin through the Business Innovation Program. Strategic IT partnership with Vasilkoff Ltd was established, and R&D for SmrtSgn began."
    },
    {
        title: "Q4 2024",
        cardTitle: "Business Plan & AI R&D",
        cardSubtitle: "Business Plan & Custom GPT Research",
        cardDetailedText: "Focus shifted to developing the business plan and preparing for the Level 2 grant for MVP development. Vasilkoff Ltd began research into custom GPT fine-tuning for legal document analysis. Iryna applied to Croc Pitch 2024, aiming to secure additional funding and strategic partnerships."
    },
    {
        title: "Q1 2025",
        cardTitle: "Marketing Research & Cloud Features",
        cardSubtitle: "Market Research and Cloud Storage",
        cardDetailedText: "Begin in-depth marketing research to refine target audience strategies. Start development of cloud features for document storage, team collaboration, and enhanced AI functionalities for businesses."
    },
    {
        title: "Q2 2025",
        cardTitle: "Crowdfunding and Grant Applications",
        cardSubtitle: "Explore Crowdfunding and Apply for Grants",
        cardDetailedText: "Plan and execute a crowdfunding campaign to gather additional support and financing. Apply for further grants to accelerate development and expand the project's reach."
    },
    {
        title: "Q3 2025",
        cardTitle: "Mobile App Development",
        cardSubtitle: "Develop SmrtSgn Mobile App",
        cardDetailedText: "Start developing mobile applications for iOS and Android, allowing users to analyze and sign documents on the go. Seamless user experience and security are prioritized."
    },
    {
        title: "Q4 2025",
        cardTitle: "International Expansion",
        cardSubtitle: "Expand to Non-English Speaking Markets",
        cardDetailedText: "Expand SmrtSgn to non-English-speaking countries by leveraging the translation feature. Explore partnerships and marketing opportunities for international reach."
    },
    {
        title: "Q1 2026",
        cardTitle: "Enterprise Solutions",
        cardSubtitle: "Launch Enterprise Solutions",
        cardDetailedText: "Introduce enterprise-level solutions with enhanced security, custom workflows, and bulk document analysis for large corporations and legal firms."
    }
];

export default TimeLineItems;
