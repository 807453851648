// src/components/FileOpen.jsx

import React from 'react';
import { FaFileUpload } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { openDB } from 'idb';
import { IDB_NAME, IKEY_NAME, ISTORE_NAME } from '../data/constants';


export default function FileOpen() {
    const navigate = useNavigate();

    async function saveToIndexedDB(file) {
        const db = await openDB(IDB_NAME, 1, {
            upgrade(db) {
                db.createObjectStore(ISTORE_NAME);
            },
        });
        console.log("iSave both the file and the file name", file, file.name);
        await db.put(ISTORE_NAME, { file, fileName: file.name }, IKEY_NAME);
    }
    

    const handleFileChange = (event) => {
        const chosenFile = event.target.files[0];
        if (chosenFile && chosenFile.type === "application/pdf") {
            const fileURL = URL.createObjectURL(chosenFile);
            saveToIndexedDB(chosenFile); 
            navigate('/document?a='+Date.now(), { state: { fileURL } });
            console.log(fileURL);
        } else {
            alert("Please select a valid PDF file.");
        }
    };

    return (
        <div className="p-6 rounded-lg bg-gradient-to-b from-purple-700 to-blue-900 max-w-md mx-auto" id='upload'>
            <div className="p-6 border border-dashed border-white flex flex-col items-center relative">
                <FaFileUpload className="w-24 h-24 mb-4 text-white" />
                <input
                    type="file"
                    accept="application/pdf"
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                    onChange={handleFileChange}
                />
                <button className="mb-4 px-4 py-2 rounded bg-white text-blue-800">Choose PDF</button>
                <div className="text-white mt-2 text-center">or drag & drop your PDF file here</div>
            </div>
        </div>
    );
}
