// src/components/Header.jsx
import React from 'react';
import Navbar from './Navbar';
import Logo from './Logo'; 

export default function Header({ user }) {
  return (
    <header className="bg-white shadow" id="header">
      <div className="container mx-auto flex justify-between items-center p-4 text-blue-800">
        <a href="/" className="text-sm font-bold uppercase flex items-end hover:text-violet-800"><Logo />
        {/* <span className='pb-2'>By Snap Sign</span>  */}
        </a> 
        <Navbar />
      </div>
    </header>
  );
}
