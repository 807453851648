// src/components/EncouragementSection.jsx

export default function EncouragementSection() {
    return (
        <section className="py-8 bg-white">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between items-center">
                    {/* Left Column */}
                    <div className="w-full sm:w-1/2 sm:p-6 py-6">
                        <h2 className="text-2xl font-bold">Safety is Free – Here's How It Works</h2>
                        <p className="mt-4 text-gray-700">
                            Worried about signing a document without fully understanding it? Let SmrtSgn help! Our AI reads your documents, flags potential risks, and explains everything in plain language.
                        </p>
                        <p className="mt-4 text-gray-700">
                            You’ll know exactly what you’re signing before you commit. And the best part? It’s completely free for basic use – no account needed to start.
                        </p>
                        <p className="mt-4 text-gray-700">
                            Your document stays on your device, and only the text is securely processed by our AI for analysis. Your privacy is our priority.
                        </p>

                    </div>

                    {/* Right Column */}
                    <div className="w-full sm:w-1/2 sm:p-6 py-6">
                        <h2 className="text-2xl font-bold">How It Works</h2>
                        <p className="mt-4 text-gray-700">
                            1. <strong>Upload your document</strong> and let our AI analyze the content.
                        </p>
                        <p className="mt-2 text-gray-700">
                            2. <strong>Review flagged risks</strong> and understand the document in plain language.
                        </p>
                        <p className="mt-2 text-gray-700">
                            3. <strong>Sign confidently</strong>, knowing you’re fully informed.
                        </p>
                        <div className="m-6 text-center bg-gray-100 rounded p-8">
                            <a
                                href="/client"
                                className="bg-blue-800 text-white py-2 px-4 rounded hover:bg-violet-800"
                            >
                                Create Free Account
                            </a>
                            <p className="m-4 text-gray-500">or</p>
                            <a
                                href="/document"
                                className="m-4 text-blue-700 hover:border-violet-800 rounded border border-blue-800 py-2 px-4"
                            >
                                Try Without an Account
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
