import { Document, Page } from "react-pdf";

export default function PdfThumbnails({numPages, file}) {
    return (
        <div className="w-full overflow-y-auto h-screen bg-gray-100">
            {numPages &&
              Array.from(new Array(numPages), (el, index) => (
                <div
                  key={`thumb_${index + 1}`}
                  className="mb-4 cursor-pointer"
                  onClick={() => {
                    const pageElement = document.getElementById(`page_${index + 1}`);
                    if (pageElement) {
                      pageElement.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  <Document file={file} loading="">
                    <Page 
                      pageNumber={index + 1}
                      width={80}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </Document>
                </div>
              ))}
        </div>
    );
}