// src/pages/RoadmapPage.jsx
import DefaultLayout from "../layouts/DefaultLayout";
import { Chrono } from 'react-chrono';
import TimeLineItems from '../data/timelines';

export default function RoadmapPage() {
    return (
        <DefaultLayout>
            <h1 className="text-bold text-lg p-4">Roadmap</h1>
            <div className="container mx-auto text-center py-10">
            <Chrono items={TimeLineItems} mode="VERTICAL" />
            </div>
        </DefaultLayout>
    );
}