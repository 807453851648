// src/layouts/DefaultLayout.jsx
import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const DefaultLayout = ({ children }) => {

  return (
    <div className="min-h-screen flex flex-col">
      <Header />

      <main className="container mx-auto p-4 flex-grow">
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default DefaultLayout;
