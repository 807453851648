import { useAuth } from "../context/AuthContext";

// src/components/EmailVerificationInstructions.jsx
export default function EmailVerificationInstructions() {
    const { user } = useAuth();
    return (
        <div className="container mx-auto p-4">
            <div className="text-center m-4 p-8 bg-gray-100 rounded max-w-md mx-auto">
                <h1 className="text-2xl font-bold">Verify your email address</h1>
                <p className="mt-4 text-gray-900">We've sent you an email to <b>{user?.email}</b> with instructions to verify your email address. If you don't receive it within a few minutes, please check your spam folder.</p>
            </div>
        </div>
    );
}
