// src/pages/PrivacyPage.jsx
import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";

export default function PrivacyPage() {
    return (
        <DefaultLayout>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
                <p className="mb-4">
                    Welcome to our Privacy Policy page. Your privacy is critically important to us.
                </p>
                <h2 className="text-2xl font-semibold mb-3">Information We Collect</h2>
                <p className="mb-4">
                    We only collect personal data that is necessary to provide you with our services, such as your name, email address, and usage data. This data is collected when you interact with our website or use our services.
                </p>
                <h2 className="text-2xl font-semibold mb-3">How We Use Your Information</h2>
                <p className="mb-4">
                    We use the information we collect to improve our services, provide you with better support, and to ensure the security of your data. We do not sell or share your personal information with third parties except where required by law.
                </p>
                <h2 className="text-2xl font-semibold mb-3">Your Data Protection Rights</h2>
                <p className="mb-4">
                    You have rights regarding your personal data, including the right to access, correct, or delete your information. You may also opt out of certain data usage by contacting us.
                </p>
                <h2 className="text-2xl font-semibold mb-3">Contact Us</h2>
                <p>
                    If you have any questions or concerns about our privacy practices, feel free to contact us at <a href="mailto:team@snapsign.com.au">team@snapsign.com.au</a>.
                </p>
            </div>
        </DefaultLayout>
    );
}
