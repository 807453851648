// src/components/Logo.jsx
import React from 'react';

const Logo = ({className, alt}) => {
  return (
    <img src={!alt ? "/images/logo-smrtsgn.svg": "/images/logo-snapsign.svg"} alt="SmrtSgn by @SnapSign" 
      className={`w-16 h-16 mx-6 ${className}`} width="60" height="60" />
  );
};

export default Logo;
